import { Tab } from '@headlessui/react';
import { Fragment } from 'react';

import classNames from '../utils/classNames';

type TabType = {
  name: string;
  content: JSX.Element;
  index?: number;
};

type TabsProps = {
  tabs: TabType[];
};

export default function Tabs({ tabs }: TabsProps) {
  const tabPanels = [];
  const tabItems = [];

  for (const tab of tabs) {
    tabItems.push(
      <Tab
        key={`tab-${tab.name}`}
        className={({ selected }) =>
          classNames(
            selected
              ? 'border-congress-blue-500 text-congress-blue-500'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-congress-blue-500',
            'whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium outline-none focus:text-congress-blue-500 focus:text-opacity-75 focus:outline-none',
          )
        }
      >
        {tab.name}
      </Tab>,
    );
    tabPanels.push(
      <Tab.Panel
        className="min-h-0 flex-1 overflow-y-auto pb-2 outline-none"
        key={`tab-panel-${tab.name}`}
        aria-label={tab.name}
      >
        {tab.content}
      </Tab.Panel>,
    );
  }

  return (
    <Tab.Group as={Fragment}>
      <Tab.List className="flex space-x-8" aria-label="Tabs">
        {tabItems}
      </Tab.List>
      <Tab.Panels as={Fragment}>{tabPanels}</Tab.Panels>
    </Tab.Group>
  );
}
