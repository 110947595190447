import { PencilIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';

import Button from './Button';
import Diagnoses from './Diagnoses';
import EmptyResults from './EmptyResults';
import Layout from './Layout';
import ResetButton from '../components/ResetButton';
import Tooltip from './Tooltip';
import type { Diagnosis } from '../contexts/DiagnosesContext';

type Props = {
  diagnoses: Diagnosis[];
  editSearch: () => void;
  findings: string[];
};

export default function Results({
  diagnoses = [],
  editSearch,
  findings = [],
}: Props) {
  return (
    <Layout
      header={
        <>
          <div className="flex-1 p-2">
            <h3 className="text-lg font-medium leading-6 text-congress-blue-900">
              Mulige diagnoser
            </h3>
            <div className="mt-1 hidden text-sm text-gray-500 sm:inline-flex">
              <Tooltip>
                <p className="flex flex-nowrap">
                  <QuestionMarkCircleIcon
                    className="mr-2 h-5 w-5 text-azure-500"
                    aria-hidden="true"
                  />
                  {findings.length}&nbsp;valgte funn ga&nbsp;
                  {diagnoses.length}&nbsp;diagnoser
                </p>
                <Tooltip.Content position="bottom">
                  <p>Valgte funn:</p>
                  <ul className="list-inside list-disc">
                    {findings.map((finding) => (
                      <li key={finding}>{finding}</li>
                    ))}
                  </ul>
                </Tooltip.Content>
              </Tooltip>
            </div>
          </div>
          <div className="ml-4 flex items-center space-x-2 md:ml-6 md:space-x-4">
            <Button type="button" onClick={editSearch}>
              <PencilIcon
                className="h-5 w-5 sm:-ml-1 sm:mr-2"
                aria-hidden="true"
              />
              <span className="sr-only sm:not-sr-only">Juster diagnosesøk</span>
            </Button>
            <ResetButton onClick={editSearch} />
          </div>
        </>
      }
    >
      <div className="min-h-full flex-1 bg-gray-100 md:px-6 md:py-4">
        {diagnoses.length ? (
          <div className="mx-auto min-h-full max-w-7xl flex-1 overflow-hidden bg-white md:rounded-md md:shadow">
            <Diagnoses showChips diagnoses={diagnoses} />
          </div>
        ) : (
          <EmptyResults />
        )}
      </div>
    </Layout>
  );
}
