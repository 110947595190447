import type { PropsWithChildren } from 'react';

type Props = {
  className?: string;
};

export default function Item({
  className,
  children,
}: PropsWithChildren<Props>) {
  return (
    <li className={className}>
      <div className="flex items-start space-x-4">{children}</div>
    </li>
  );
}
