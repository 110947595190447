import { createContext, useState } from 'react';
import type { PropsWithChildren } from 'react';

interface SidePanelProviderProps {
  defaultState?: boolean;
}

type SidePanelContextValue = [
  isOpen: boolean,
  setSidebarOpen: (isOpen: boolean) => void,
];

const SidePanelContext = createContext<SidePanelContextValue>(
  {} as SidePanelContextValue,
);

function SidePanelProvider({
  children,
  defaultState = false,
}: PropsWithChildren<SidePanelProviderProps>) {
  const [sidebarOpen, setSidebarOpen] = useState(defaultState);

  return (
    <SidePanelContext.Provider value={[sidebarOpen, setSidebarOpen]}>
      {children}
    </SidePanelContext.Provider>
  );
}

export { SidePanelContext, SidePanelProvider };
