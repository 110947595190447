import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Results from '../layouts/Results';
import { DiagnosesContext, Diagnosis } from '../contexts/DiagnosesContext';
import { SignsContext } from '../contexts/SignsContext';

type Props = {
  openMenu: () => void;
};

export default function Diagnoses({ openMenu }: Props) {
  const { diagnoses } = useContext(DiagnosesContext);
  const { signs, signsByCategory } = useContext(SignsContext);
  const [matches, setMatches] = useState<Diagnosis[]>([]);
  const [searchParams] = useSearchParams();

  const requiredCategory = signsByCategory.keys().next().value;

  const requiredSigns = useMemo(
    () =>
      new Set(
        signs.reduce((acc, sign) => {
          if (sign.category === requiredCategory) {
            acc.add(sign.name);
          }
          return acc;
        }, new Set<string>()),
      ),
    [requiredCategory, signs],
  );

  useEffect(() => {
    const signs = searchParams.getAll('signs');
    let displayMatches = false;
    if (signs.length > 0) {
      for (const diagnosis of diagnoses) {
        diagnosis.matchExpected = 0;
        diagnosis.matchSupportives = 0;

        for (const sign of signs) {
          if (requiredSigns.has(sign)) {
            displayMatches = true;
          }
          if (diagnosis.expected.has(sign)) {
            diagnosis.matchExpected += 1;
          }
          if (diagnosis.supportives.has(sign)) {
            diagnosis.matchSupportives += 1;
          }
        }
      }
      if (displayMatches) {
        setMatches(diagnoses.filter(({ matchExpected }) => matchExpected > 0));
      }
    } else {
      setMatches([]);
    }
  }, [diagnoses, requiredSigns, searchParams]);

  return (
    <Results
      editSearch={openMenu}
      diagnoses={matches.sort(Diagnosis.compare)}
      findings={searchParams.getAll('signs')}
    />
  );
}
