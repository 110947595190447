import { createElement, forwardRef } from 'react';
import type {
  ButtonHTMLAttributes,
  ComponentProps,
  ComponentType,
  PropsWithChildren,
  RefObject,
} from 'react';

type ButtonProps<T> = ButtonHTMLAttributes<HTMLButtonElement> &
  T & {
    as?: string | ComponentType<T>;
    className?: string;
    variant?: InputVariants;
  };

function Button<T extends keyof JSX.IntrinsicElements>(
  {
    as = 'button',
    children,
    className = '',
    variant = 'secondary',
    ...props
  }: PropsWithChildren<ButtonProps<T> & ComponentProps<T>>,
  ref: RefObject<HTMLElement>,
) {
  const classes = [
    'relative',
    'inline-flex',
    'items-center',
    'justify-center',
    'px-3',
    'py-2',
    'border',
    'text-sm',
    'font-medium',
    'rounded-md',
    'focus:outline-none',
    'focus:ring-1',
    'focus:ring-azure-500',
    'focus:border-azure-500',
    'transition-colors',
    'ease-in-out',
    'duration-300',
    'select-none',
    ...className.split(' '),
  ];

  switch (variant) {
    case 'primary':
      classes.push(
        'border-azure-500',
        'bg-azure-500',
        'hover:bg-azure-300',
        'hover:border-azure-300',
        'text-white',
        'shadow-sm',
      );
      break;
    case 'secondary':
      classes.push(
        'border-azure-700',
        'text-azure-700',
        'bg-gray-50',
        'hover:bg-gray-200',
        'hover:text-azure-500',
        'shadow-sm',
      );
      break;
    case 'tertiary':
      classes.push(
        'border-transparent',
        'text-azure-700',
        'bg-transparent',
        'hover:text-azure-500',
        'hover:bg-gray-300',
      );
      break;
    default:
      classes.push();
      break;
  }

  return createElement(
    as,
    {
      ...props,
      className: classes.join(' '),
      ref,
    },
    children,
  );
}

export default forwardRef(Button);
