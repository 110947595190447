import { Outlet, Route, Routes } from 'react-router-dom';

import FourOFour from './layouts/FourOFour';
import Home from './layouts/Home';
import Layout from './layouts/Layout';
import DisordersDirectory from './components/DisordersDirectory';
import FAQ from './components/FAQ';
import Validation from './components/Validation';
import { SidePanelProvider } from './contexts/SidePanelStateContext';
import { DiagnosesProvider } from './contexts/DiagnosesContext';
import { SignsProvider } from './contexts/SignsContext';

export default function App() {
  return (
    <DiagnosesProvider>
      <SignsProvider>
        <Routes>
          <Route
            path="/"
            element={
              <SidePanelProvider>
                <Home />
              </SidePanelProvider>
            }
          />
          <Route path="/directory" element={<DisordersDirectory />} />
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route path="/validation" element={<Validation />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="*" element={<FourOFour />} />
          </Route>
        </Routes>
      </SignsProvider>
    </DiagnosesProvider>
  );
}
