import { useContext, useState } from 'react';
import { BackspaceIcon, SearchIcon } from '@heroicons/react/solid';

import { SignsContext } from '../contexts/SignsContext';
import Button from '../layouts/Button';
import Input from '../layouts/Input';
import List from '../layouts/List';
import SignCheckBox from '../layouts/Sign';
import Signs from '../layouts/Signs';

export type Category = {
  name: string;
  subCategories: string[];
};

export default function SignsWithSearch() {
  const { signsByCategory, signs } = useContext(SignsContext);
  const [search, setSearch] = useState('');
  const [filteredSigns, setFilteredSigns] = useState([]);

  const handleSearch = ({ target: { value } }) => {
    setSearch(value);
    setFilteredSigns(() =>
      value.length > 0
        ? signs.filter(
            ({ definition, name }) =>
              name.toLowerCase().includes(value.toLowerCase()) ||
              definition.toLowerCase().includes(value.toLowerCase()),
          )
        : [],
    );
  };

  const handleReset = () => {
    setSearch('');
    setFilteredSigns([]);
  };

  return (
    <>
      <div className="flex space-x-4 py-2">
        <div className="min-w-0 flex-1">
          <label htmlFor="search" className="sr-only">
            Search findings
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon
                className="h-5 w-5 text-congress-blue-500"
                aria-hidden="true"
              />
            </div>
            <Input
              autoComplete="off"
              className="block w-full rounded-md border-gray-300 pl-10 focus:border-azure-500 focus:ring-azure-500 sm:text-sm"
              dataList={Array.from(new Set(signs.map(({ name }) => name)))}
              id="search"
              name="search"
              onChange={handleSearch}
              placeholder="Søk etter funn"
              type="search"
              value={search}
            />
          </div>
        </div>
        <Button type="button" onClick={handleReset}>
          <BackspaceIcon className="h-5 w-5 text-current" aria-hidden="true" />
          <span className="sr-only">Reset</span>
        </Button>
      </div>
      {filteredSigns.length > 0 ? (
        <div className="min-h-0 flex-1 overflow-y-auto">
          <List>
            {filteredSigns.map((symptom) => (
              <List.Item
                className="py-4"
                key={`search-${symptom.category}-${symptom.subCategory}-${symptom.name}`}
              >
                <SignCheckBox {...symptom} />
              </List.Item>
            ))}
          </List>
        </div>
      ) : (
        <Signs signsByCategory={signsByCategory} />
      )}
    </>
  );
}
