import Tabs from './Tabs';
import AreaSigns from './AreaSigns';
import map from '../utils/map';

export type Category = {
  name: string;
  subCategories: string[];
};

export default function Signs({ signsByCategory }) {
  return (
    <Tabs
      tabs={map(
        signsByCategory.entries(),
        ([category, signsBySubcategories]) => ({
          name: category,
          content: (
            <AreaSigns
              category={category}
              key={category}
              findingSubCategories={signsBySubcategories}
            />
          ),
        }),
      )}
    />
  );
}
