import type { AnchorHTMLAttributes, PropsWithChildren } from 'react';

export default function Anchor({
  children,
  className = '',
  ...props
}: PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>) {
  return (
    <a
      {...props}
      className={'text-congress-blue-600 hover:underline hover:opacity-75'.concat(
        className,
      )}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}
