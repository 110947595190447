import { useCallback, useContext, useState } from 'react';
import type { ChangeEventHandler } from 'react';
import { SearchIcon } from '@heroicons/react/solid';

import { DiagnosesContext, Diagnosis } from '../contexts/DiagnosesContext';
import Diagnoses from '../layouts/Diagnoses';
import Layout from '../layouts/Layout';
import Input from '../layouts/Input';

export default function DisordersDirectory() {
  const { diagnoses } = useContext(DiagnosesContext);
  const [searchQuery, setSearchQuery] = useState('');

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setSearchQuery(value);
    },
    [],
  );

  return (
    <Layout
      header={
        <>
          <div className="hidden items-center p-2 md:flex">
            <h3 className="text-lg font-medium leading-6 text-congress-blue-900">
              Diagnoseliste
            </h3>
          </div>
          <div className="ml-4 flex items-center space-x-2 md:ml-6 md:space-x-4">
            <div>
              <label
                htmlFor="disorder"
                // className="block text-sm font-medium text-gray-700"
                className="sr-only"
              >
                Søk etter diagnosenavn, gen eller Orpha Code
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon
                    className="h-5 w-5 text-congress-blue-500"
                    aria-hidden="true"
                  />
                </div>
                <Input
                  autoComplete="off"
                  dataList={diagnoses.map(({ name: item }) => item)}
                  name="disorder"
                  onChange={onChange}
                  placeholder=" diagnosenavn, gen, orphakode"
                  type="text"
                />
              </div>
            </div>
          </div>
        </>
      }
    >
      <div className="min-h-full flex-1 bg-gray-100 md:px-6 md:py-4">
        <div className="mx-auto min-h-full max-w-7xl flex-1 overflow-hidden bg-white md:rounded-md md:shadow">
          <Diagnoses
            diagnoses={diagnoses
              .filter((diagnosis) => diagnosis.matchSearch(searchQuery))
              .sort(Diagnosis.compareName)}
          />
        </div>
      </div>
    </Layout>
  );
}
