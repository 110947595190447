import { NavLink } from 'react-router-dom';
// import { BeakerIcon } from '@heroicons/react/outline';

/* This example requires Tailwind CSS v2.0+ */
export default function FourOFour() {
  return (
    <>
      <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <NavLink to="/" className="inline-flex">
              <span className="sr-only">Uniqe tenner</span>
              {/* <BeakerIcon className="h-16 w-16 text-congress-blue-600" /> */}
              <img
                alt="Logomark"
                src="/logo-192.png"
                className="h-24 w-24 object-contain"
              />
            </NavLink>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold uppercase tracking-wide text-congress-blue-600">
                404 error
              </p>
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-congress-blue-900 sm:text-5xl">
                Page not found.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6">
                <NavLink
                  to="/"
                  className="text-base font-medium text-congress-blue-600 hover:text-congress-blue-500"
                >
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </NavLink>
              </div>
            </div>
          </div>
        </main>
        {/* <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center space-x-4">
            <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Contact Support
            </a>
            <span className="inline-block border-l border-gray-300" aria-hidden="true" />
            <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Status
            </a>
            <span className="inline-block border-l border-gray-300" aria-hidden="true" />
            <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Twitter
            </a>
          </nav>
        </footer> */}
      </div>
    </>
  );
}
