import type { PropsWithChildren } from 'react';

import Header from './Header';
import Main from './Main';
import Sidebar from './Sidebar';

type Props = {
  header?: JSX.Element;
};

export default function Layout({ header, children }: PropsWithChildren<Props>) {
  return (
    <>
      <Sidebar />
      <div className="flex h-full min-h-screen flex-1 flex-col md:pl-64">
        <Header>{header}</Header>
        <Main>{children}</Main>
      </div>
    </>
  );
}
