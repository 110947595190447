export type PagesLink = {
  title: string;
  link: string;
};

export const pages: PagesLink[] = [
  {
    title: 'Søkeverktøy',
    link: '/',
  },
  {
    title: 'Diagnoseliste',
    link: '/directory',
  },
  {
    title: 'Ofte stilte spørsmål',
    link: '/faq',
  },
];
