/* This example requires Tailwind CSS v2.0+ */
import type { PropsWithChildren } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import Button from './Button';

type SidePanelProps = {
  defaultOpen?: boolean;
  title?: string;
  isOpen: boolean;
  openPanel: (open: boolean) => void;
};

export default function SidePanel({
  children,
  title = '',
  isOpen,
  openPanel,
}: PropsWithChildren<SidePanelProps>) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="aside" className="relative z-10" onClose={openPanel}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="l-10 pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                  <div className="flex h-screen max-h-screen flex-col bg-white py-6 shadow-xl">
                    <div className="px-2 sm:px-4">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="flex-1 text-lg font-medium text-congress-blue-900">
                          {title}
                        </Dialog.Title>

                        <div className="ml-3 flex h-7 items-center">
                          <div className="hidden sm:mr-2 sm:block">
                            <Button
                              onClick={() => openPanel(false)}
                              variant="primary"
                            >
                              Vis diagnoser
                            </Button>
                          </div>
                          <Button
                            onClick={() => openPanel(false)}
                            type="button"
                            variant="tertiary"
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="flex max-h-full min-h-full flex-col py-2 px-2 sm:px-4">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
