import type { PropsWithChildren } from 'react';
import classNames from 'classnames';

type Props = {
  color?: 'light' | 'dark';
  position?: 'top' | 'bottom' | 'left' | 'right';
};

export default function Content({
  children,
  color = 'dark',
  position = 'top',
}: PropsWithChildren<Props>) {
  return (
    <div
      className={classNames(
        'invisible absolute z-10 hidden h-auto w-80 min-w-min rounded-md py-2 px-4 opacity-0 shadow transition-opacity delay-200 ease-in-out group-hover:visible group-hover:block group-hover:opacity-95 group-focus:visible group-focus:block group-focus:opacity-95',
        {
          'bg-gray-50 text-current': color === 'light',
          'bg-gray-900 text-white': color === 'dark',
        },
        {
          '-trasnlate-y-1 bottom-full left-1/2 -translate-x-1/2 transform':
            position === 'top',
          'top-full left-1/2 -translate-x-1/2 translate-y-1 transform':
            position === 'bottom',
          'left-full top-1/2 translate-x-1 -translate-y-1/2 transform':
            position === 'right',
          'right-full top-1/2 -translate-x-1 -translate-y-1/2 transform':
            position === 'left',
        },
      )}
      role="tooltip"
    >
      <span className="text-xs">{children}</span>
    </div>
  );
}
