import { useCallback, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SidePanelContext } from '../contexts/SidePanelStateContext';
import Diagnoses from '../components/Diagnoses';
import SignsWithSearch from '../components/SignsWithSearch';
import SidePanel from './SidePanel';

export default function Home() {
  const [isOpen, setIsOpen] = useContext(SidePanelContext);
  const [searchParams] = useSearchParams();

  const openMenu = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  useEffect(() => {
    if (searchParams.get('signs') === null) {
      setIsOpen(true);
    }
  }, [searchParams, setIsOpen]);

  return (
    <>
      <Diagnoses openMenu={openMenu} />
      <SidePanel title="Kliniske funn" openPanel={setIsOpen} isOpen={isOpen}>
        <p className="text-medium py-2 text-gray-600">
          Velg ett eller flere kliniske funn der minst ett er i munnhulen
        </p>
        <SignsWithSearch />
      </SidePanel>
    </>
  );
}
