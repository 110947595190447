import { Disclosure, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';

import List from './List';
import SignCheckBox from './Sign';
import type { Sign } from '../contexts/SignsContext';
import Chip from './Chip';

type AreaSignsProps = {
  category: string;
  findingSubCategories?: Map<string, Sign[]>;
};

export default function AreaSigns({
  category,
  findingSubCategories,
}: AreaSignsProps) {
  const [searchParams] = useSearchParams();

  const countSelectedFindings = useCallback(
    (signs: Sign[]) =>
      signs.filter((sign) => searchParams.getAll('signs').includes(sign.name)),
    [searchParams],
  );

  const subCategories = useCallback(() => {
    const subCategories: JSX.Element[] = [];
    if (findingSubCategories) {
      findingSubCategories.forEach((signs, subCategory) => {
        const selectedFindings = countSelectedFindings(signs);
        subCategories.push(
          <Disclosure as="div" className="block pt-4" key={subCategory}>
            {({ open }) => (
              <>
                <Disclosure.Button
                  as="dt"
                  className="flex flex-1 cursor-pointer items-center space-x-2 bg-white text-left text-gray-400 hover:text-azure-500"
                >
                  <ChevronRightIcon
                    className={classNames(
                      open ? 'rotate-90' : 'rotate-0',
                      'h-5 w-5 transform transition duration-75 ease-out',
                    )}
                    aria-hidden="true"
                  />
                  <div className="relative font-medium text-congress-blue-900">
                    <p>{subCategory}</p>
                    <div
                      className={classNames(
                        'absolute -right-6 bottom-2 opacity-75',
                        {
                          hidden: selectedFindings.length === 0,
                        },
                      )}
                    >
                      <Chip variant="warning">{selectedFindings.length}</Chip>
                    </div>
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform h-o opacity-0"
                  enterTo="transform h-max opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform h-max opacity-100"
                  leaveTo="transform h-o opacity-0"
                >
                  <Disclosure.Panel as="dd" className="pt-2 pb-4 pr-12">
                    <List>
                      {signs.map((finding) => (
                        <List.Item
                          className="py-4"
                          key={`${category}-${category}-${finding.name}`}
                        >
                          <SignCheckBox {...finding} />
                        </List.Item>
                      ))}
                    </List>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>,
        );
      });
    }
    return subCategories;
  }, [category, countSelectedFindings, findingSubCategories]);

  return <dl className="min-h-max">{subCategories()}</dl>;
}
