import classNames from 'classnames';
import type { PropsWithChildren } from 'react';

type Props = {
  variant?: 'success' | 'warning' | 'neutral' | 'error';
};

export default function Chip({
  children,
  variant = 'neutral',
}: PropsWithChildren<Props>) {
  return (
    <span
      className={classNames(
        {
          'bg-red-100 text-red-900': variant === 'error',
          'bg-green-100 text-green-800': variant === 'success',
          'bg-yellow-100 text-yellow-800': variant === 'warning',
          'bg-gray-100 text-gray-800': variant === 'neutral',
        },
        'ml-2 inline-flex items-center space-x-2 rounded-full px-3 py-0.5 text-xs font-medium leading-5',
      )}
    >
      {children}
    </span>
  );
}
