import type { PropsWithChildren } from 'react';

import Item from './Item';

export default function List({ children }: PropsWithChildren<{}>) {
  return (
    <div className="flow-root">
      <ul className="divide-y divide-gray-200">{children}</ul>
    </div>
  );
}

List.Item = Item;
