// loop through an iterator the same way we will do on an array with Array.prototype.map()
export default function map<T, R>(
  iterator: IterableIterator<T>,
  func: (value: T, index: number) => R,
  res: R[] = [],
): R[] {
  const { done, value } = iterator.next();
  if (done) {
    return res;
  }
  return map(iterator, func, [...res, func(value, res.length)]);
}
